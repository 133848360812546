/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #a1a1a1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8d8d8d;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
}
.inline {
    display: inline-block;
}
.w-25 {
    width: 25%;
}
.bg-white {
    background-color: #FFF ;
}
.p-50 {
    padding: 0.5rem;
}