@tailwind base;
@tailwind components;
@tailwind utilities;


.shader {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(219, 214, 225);
    box-shadow: rgb(43 34 51 / 4%) 0px 1px 4px;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,700;0,800;1,100;1,200;1,300;1,600;1,700;1,800&display=swap');
body {
    font-family: 'Poppins', sans-serif , 'Arial';
    color: #151515;

}
input {
    font-family: 'Poppins', sans-serif , 'Arial';
    color: #151515;
}

.f-col {
    @apply flex flex-col;
}
.f-row {
    @apply flex flex-row;
}
.centered {
    @apply items-center justify-center;
}

.f-col-center {
    @apply flex flex-col items-center;
}

.f-col-center-center {
    @apply flex flex-col items-center justify-center;
}

.f-row-between {
    @apply flex justify-between;
}
.f-row-center {
    @apply flex justify-center;
}

.f-row-center-center {
    @apply flex items-center justify-center;
}

.input-style {
    @apply w-full text-gray-800 border border-gray-200 py-4 px-2 text-sm  focus:border-gray-400 focus:outline-none focus:ring-1;
}

.ruleGroup-combinators, .rule-fields, .rule-operators, .rule-value {
    @apply input-style h-9 rounded bg-gray-900

}
.rule-remove{
    @apply bg-red-700 border-red-400 border text-white rounded   w-6 p-4 h-6 flex items-center justify-center cursor-pointer;
}
.ruleGroup-addRule, .ruleGroup-addGroup{
    @apply bg-green-700 border-green-400 border text-white rounded    p-1   flex items-center justify-center cursor-pointer;
}
